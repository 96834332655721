
#login_container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.login {
	width: 500px;
	max-width: 100%;
	margin: 20px;
}

// Mehr Randabstand für Hauptinhalt
.admin-main {
	padding: 10px 40px;
}

// Mehr Abrundung bei Vorschaubildern
.thumbnail {
	border-radius: $border-radius-large;
}

// Dropzone (Bilder-Upload)
#dropzone {
	margin: 20px 0;
}

//Abstand um Link-Dropdown
#url-editor {
	padding: 5px;
}

//Sortierbuttons mit Abstand
td a.btn {
	&:first-of-type {
		margin-right: 2px;
	}

	&:last-of-type {
		margin-left: 2px;
	}
}

// Pointer bei Bild-Auswahl
.image-select {
	cursor: pointer;
}

img.image-select {
	max-height: 600px;
}

/////////////////////////////////////////////////////////////////////////////////////
// Von https://github.com/steveathon/bootstrap-wysiwyg/blob/master/css/style.css   //
/////////////////////////////////////////////////////////////////////////////////////
//Editor-div
.editor {
	min-height: 250px;

	//	background-color: white;
	margin-top: 10px;
	border: 1px solid $primary;
	padding: 4px;
	box-sizing: content-box;
	border-radius: $border-radius-large;
	outline: none;
}

// Toolbar nicht auswählbar
div[data-role="editor-toolbar"] {
	user-select: none;
}

.dropdown-menu a {
	cursor: pointer;
}

.btn-toolbar {
	padding: 10px 0;

	&.affix-top {
		position: relative;
	}

	&.affix {
		position: fixed !important;
		padding: 0 5px 0 0;
		z-index: 50;
		top: 10px;
		background-color: #fff;
		border: 1px solid $primary;
		border-radius: $border-radius-large;
	}

	&.affix-bottom {

	}
}

// Style von Upload-Buttons (http://stackoverflow.com/a/25053973)
label.btn input {
	display: none;
}

////////////////////////
//Sonstiges zum Editor//
////////////////////////
// Eigene Boxen um Editor
.preview {
	border: 1px solid $primary;
}

.admin-main .btn,
#edit_title {
	margin-top: 10px;
	margin-bottom: 10px;
}

form.inline-button {
	display: inline;
}

////////////////////////////////////////////////////////////////////////////////////////
//Original von                                                                        //
//https://github.com/twbs/bootstrap/blob/master/docs/examples/dashboard/dashboard.css //
//verändert und angepasst                                                             //
////////////////////////////////////////////////////////////////////////////////////////
// Sidebar
.sidebar {

	//	position: fixed;
	//	top: 51px;
	//	bottom: 0;
	//	left: 0;
	//	z-index: 1000;
	//	display: block;
	//	padding: 20px;
	//	overflow-x: hidden;
	//	overflow-y: auto;
	// Scrollable contents if viewport is shorter than content.
	background-color: $sidebar-background-color;
	border-right: 1px solid darken($sidebar-background-color, 4%);
}

// Navbar
.navbar-inverse {
	border-radius: 0;
}

#admin-navbar-right > li > a:hover {
	background-color: lighten(black, 40%) !important;
}

// Sidebar navigation
.nav-sidebar {
	margin-right: -21px;
	/* 20px padding + 1px border */
	margin-bottom: 20px;
	margin-left: -20px;

	> li > a {
		padding-right: 20px;
		padding-left: 20px;
	}
	.overview-item {
		font-weight: bold;
	}

	a.active,
	a.active:hover,
	a.active:focus {
		color: $sidebar-active-color;
		background-color: $sidebar-active-bg-color;
	}
}
