//Eigene
$secondary-original: #a48d3d;
$secondary: #967110;
$background-color: #cfcfcf;
$gray-lightest: #f5f5f5;
$sidebar-background-color: #f5f5f5;
$sidebar-active-bg-color: #428bca;
$sidebar-active-color: white;
$wrapper-margin: 20px; //Abstand von Box nach oben und unten



$primary: #9b9b9b;
$link-color: $secondary;
//$font-family-sans-serif: -apple-system, "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Liberation Sans", Arial, sans-serif;
$headings-color: darken($primary, 15%);
//$headings-color:$gray-600;

$border-radius-base: 5px;
$border-radius-large: 9px;
$border-radius-small: 4px;
//$pagination-disabled-bg: $gray-300;
$input-border-focus: $primary;
$input-color-placeholder: $primary;
$thumbnail-border-radius: $border-radius-large;
$sidebar-background-color: #f5f5f5;
$sidebar-active-bg-color: #428bca;
$sidebar-active-color: white;

//$navbar-padding-y: 0;

$lg-path-fonts: "/static/libs/lightgallery/dist/fonts";
$lg-path-images: '/static/libs/lightgallery/dist/img';
