@import "variables.scss";

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/easymde/dist/easymde.min";
@import "node_modules/leaflet/dist/leaflet";
@import "node_modules/lightgallery.js/src/sass/lightgallery";
@import "node_modules/dropzone/dist/dropzone";
@import "adminTheme";

/*!
 * by Lukas Winkler <webdesign@lw1.at>
 * based on Bootstrap variables and mixins
 * Builddate: $$DATE$$
 */

//Mixin
.shadow {
	box-shadow: 5px 10px 10px 0 rgba(78, 78, 78, 0.6);
}

.wine-icon {
	margin-top: 20px;
	width: 130px;

	&.Federspiel {
		width: 60px;
	}
}

.content {
	background-color: #fff; // Inhalt vor weißem Hintergrund
}

footer.public {
	font-size: 14px;
	background-color: $light;

	@include media-breakpoint-up(sm) {
		margin-bottom: $wrapper-margin; // Abstand vom unteren Bildschirmrand
		@include border-bottom-radius(9px); // untere Abrundung
	}
	//border-top: 2px solid $dropdown-divider-bg; // Trennline zum Inhalt

	address {
		display: flex;
		justify-content: space-between;
	}

	ul {
		-webkit-padding-start: 0;
		-moz-padding-start: 0;
		display: flex;
		flex-wrap: wrap;

		&.right {
			justify-self: right;
		}
	}

	li {
		display: block;
		justify-content: left;
		align-content: center;
		margin: 0 10px;

		svg {
			height: 14px;
			fill: currentColor;
		}
	}

}

// Inhaltsbox im Vordergrund mit Schatten
.content,
footer.public,
#nav-container,
#headerimage {
	@extend .shadow;
	//z-index: 2;
}

// Schatten unter Hauptboxen
//body > .alert {
//	@extend .shadow;
//}

body.background {
	background: $background-color;

	@include media-breakpoint-up(sm) {
		// Hintergrund von http://subtlepatterns.com/wine-cork/
		background-image: url("/static/images/cork_pattern_dark.png");
		background-attachment: fixed;
	}

}

// Navigationsleiste bis zum Rand
#nav-container {
	padding: 0;
}

// Kein Abstand zwischen .navbar und .content
.navbar {
	margin-bottom: 0;
}

// Globus-Icon
.navbar-lang.navbar-right > li > a {
	padding: 0;

	svg {
		width: 30px;
		height: 30px;
	}
}

.main {
	padding: 20px;
}


#contact td {
	padding-top: 5px;

	svg {
		width: 14px;
		height: 14px;
	}

	&:first-child {
		text-align: right;
		padding-right: 8px;
		min-width: 45px;
	}
}

.pagination {
	margin: 0;
}

//http://stackoverflow.com/a/25517025
@media (min-width: 768px) {
	.vertical-align {
		display: flex;
		align-items: center;
	}
}

.list-unstyled {
	margin-left: 8px;
}

//Piwik-iframe
iframe {
	border: 0;
	width: 100%;
	height: 300px;

	@include media-breakpoint-up(xs) {
		height: 200px;
	}
	@include media-breakpoint-up(sm) {
		height: 150px;
	}
}

//Titelbild: Bis zum Rand, Abstand von oben
#headerimage {
	padding: 0;
	position: relative;

	.logowrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		.logo {
			width: 65%;
			height: auto;
			display: block;
		}
	}


	@include media-breakpoint-up(sm) {
		margin-top: $wrapper-margin;

		img.headerimg {
			@include border-top-radius(9px);
		}
	}
}

//Seitenüberschrift
.page-header {
	margin: 0 0 20px;
	padding-bottom: 0;
	border-bottom: 1px solid $gray-200;
}

h1, h2, h3, h4, h5, h6 {
	color: $headings-color;
}


// Nicht drucken:
@media print {
	#headerimage,
	ul.social-network {
		display: none !important;
	}
}


// Icons im Footer
footer.public .fa {
	padding: 0 2px;
}

//Weinübersicht
.card.wine {
	padding: 10px;
	margin-bottom: 1.5rem;

	.card-body {
		padding: 0.5rem;

		table {
			margin-bottom: 0;
		}
	}
}

#gallery {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	.gallery-wrapper {
		padding: 20px;
		position: relative;

		.delete {
			position: absolute;
			top: 30px;
			right: 30px;
			font-size: 20px;
		}

		img {
			border-radius: 9px;
		}

	}
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {

	//	cursor: not-allowed;
	//	background-color: $gray-lightest;
}

//////////////////
//Social Buttons//
//////////////////

ul.social-network {
	list-style: none;
	display: inline;
	margin-left: 0 !important;
	padding: 0;

	li {
		display: inline;
		margin: 0 5px;
	}

	a {
		background-color: $primary;
		display: inline-block;
		margin: 0 auto;
		border-radius: 50%;
		padding: 13px;
		//transition: background-color 0.4s;

		svg {
			display: block;
			height: 14px;
			width: 14px;
			fill: white;
		}

		&.icoRss:hover {
			background-color: #f56505;
		}

		&.icoFacebook:hover {
			background-color: #3b5998;
		}

		&.icoTwitter:hover {
			background-color: #3cf;
		}

		&.icoGoogle:hover {
			background-color: #bd3518;
		}

	}
}

.fa.fa-mobile {
	font-size: ceil(($font-size-base * 1.20));
}

.yes-no {
	max-width: 500px;
}

//Referenzenliste

//li > a {
//	color: $body-color;
//}

#welcome blockquote {
	@include media-breakpoint-down(md) {
		font-size: $font-size-base * 1.125;
	}
}

#carousel-mainpage {
	img {
		height: auto;
	}
}

.navbar-admin {
	padding-bottom: 0;
	padding-top: 0;

	&.active {
		border-bottom: white solid;
	}
}

.pager {
	list-style: none;
	padding: 0;
	display: flex;

	.next {
		margin-left: auto;
	}

	.preview {
		margin-right: auto;
	}
}

#notify-list {
	position: fixed;
	max-width: 500px;
	bottom: 10px;
	right: 10px;

	.notify {
		transition: opacity 1s;
		margin: 0;
		//font-family: Hack, monospace, monospace;
		padding: 20px;
		opacity: 0;

		&.visible {
			opacity: 1;
		}

		@each $color, $value in $theme-colors {
			&.#{$color} {
				@include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
			}
		}

	}

}

#wine-table {
	.grab-row {
		padding-left: 0;
		padding-right: 0;
		display: flex;
		justify-content: center;
		align-content: center;
		cursor: grab;
	}

	svg {
		width: 16px;
		height: 16px
	}
}

blockquote {
	@extend .blockquote;
	padding-left: 1rem;
	border-left: 5px solid #eee;
}

.overview-list .card {
	margin-bottom: 2rem;
}

small, .small {
	font-size: 65%;
}

.text-muted a {
	color: desaturate(lighten($secondary, 15), 30);
}
